<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xml:space="preserve"
    width="24"
    height="24"
    fill="currentColor"
    aria-hidden="true"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    aria-labelledby="Instagram"
  >
    <path
      d="M12 8.8c-1.7 0-3.1 1.4-3.1 3.1s1.4 3.1 3.1 3.1 3.1-1.4 3.1-3.1c0-1.7-1.4-3.1-3.1-3.1zM18.8 0H5.2C2.3 0 0 2.3 0 5.2v13.4c0 2.9 2.3 5.2 5.2 5.2h13.6c2.9 0 5.2-2.3 5.2-5.2V5.2C24 2.3 21.7 0 18.8 0zM20.7 15.9c0 2.8-2.3 5.1-5.1 5.1H8.3c-2.8 0-5.1-2.3-5.1-5.1V8.1c0-2.8 2.3-5.1 5.1-5.1h7.3c2.8 0 5.1 2.3 5.1 5.1v7.8zM15.6 4.4H8.3c-1 0-1.9 0.4-2.6 1.1-0.7 0.7-1.1 1.6-1.1 2.6v7.8c0 1 0.4 1.9 1.1 2.6 0.7 0.7 1.6 1.1 2.6 1.1h7.3c1 0 1.9-0.4 2.6-1.1 0.7-0.7 1.1-1.6 1.1-2.6V8.1c0-1-0.4-1.9-1.1-2.6-0.7-0.7-1.6-1.1-2.6-1.1zM12 16.8c-2.6 0-4.8-2.2-4.8-4.8S9.3 7.2 12 7.2s4.8 2.2 4.8 4.8-2.1 4.8-4.8 4.8zM16.9 8.2c-0.6 0-1.1-0.5-1.1-1.2 0-0.6 0.5-1.1 1.1-1.1s1.1 0.5 1.1 1.1c0 0.7-0.5 1.2-1.1 1.2z"
    />
  </svg>
</template>
